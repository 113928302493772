import { Container, VStack } from '@chakra-ui/react';
import HowItWorksContainer from '../containers/HowItWorksContainer';
import JarsContainer from '../containers/JarsContainer';

const ConnectedPage = () => {
  return (
    <Container maxW="lg" pt="12" pb="16">
      <VStack spacing="6">
        <HowItWorksContainer />
        <JarsContainer />
      </VStack>
    </Container>
  );
};

export default ConnectedPage;
