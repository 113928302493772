const HiWIllustration: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="173"
    height="73"
    viewBox="0 0 173 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_446_2857)">
      <path
        d="M0 53.75L32.26 6.33L42.4 31.96L71.74 0L76.77 12.82L101.63 1.11L91.35 49.73L126.89 11.55L131.77 34.91L173 6.33L159.03 50.01L146.34 33.38L112.74 62.69L111.81 51.78L80.43 73L64.4 35.11L0 53.75Z"
        fill="#D9A049"
      />
      <path
        d="M100.4 26.34C86.99 2.04001 64.08 15.26 64.08 15.26"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M92.72 23.73L100.55 26.64L102.27 18.92"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M91.68 45.1C86.32 38.49 80.62 36.31 75.63 36.12"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M85.1 44.16L91.85 45.38L92.1 38.92"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M145.89 37.74L119.43 22.44L101.25 53.47L127.71 68.77L145.89 37.74Z"
        fill="#f2af4a"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M139.59 38.19L122.11 28.08C121.29 27.61 120.22 27.88 119.73 28.7L110.45 44.52C109.97 45.34 110.25 46.39 111.07 46.88L128.55 56.98C129.37 57.45 130.44 57.18 130.93 56.36L140.21 40.54C140.71 39.72 140.43 38.66 139.59 38.19Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M106.24 50.08L124.89 60.87"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M56.04 27.67C59.92 25.72 61.49 21.02 59.55 17.17C57.61 13.32 52.9 11.78 49.03 13.73C45.16 15.68 43.58 20.38 45.52 24.23C47.46 28.08 52.16 29.62 56.04 27.67Z"
        fill="#f2af4a"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M20.59 40.84C18.82 43.34 19.39 46.78 21.87 48.54C24.35 50.3 27.79 49.7 29.56 47.21C31.33 44.71 30.76 41.27 28.28 39.51C25.81 37.74 22.36 38.34 20.59 40.84Z"
        fill="#f2af4a"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M147.22 42.98C150.28 42.98 152.76 40.52 152.76 37.48C152.76 34.44 150.28 31.98 147.22 31.98C144.16 31.98 141.68 34.44 141.68 37.48C141.68 40.52 144.16 42.98 147.22 42.98Z"
        fill="#f2af4a"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M46.49 63.93C53.99 66.32 62 62.22 64.38 54.77C66.76 47.32 62.6 39.34 55.1 36.95C47.6 34.56 39.59 38.66 37.22 46.11C34.83 53.55 38.99 61.53 46.49 63.93Z"
        fill="#f2af4a"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M140.52 25.83C144.86 25.83 148.38 22.34 148.38 18.03C148.38 13.72 144.86 10.23 140.52 10.23C136.18 10.23 132.66 13.72 132.66 18.03C132.66 22.34 136.18 25.83 140.52 25.83Z"
        fill="#f2af4a"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M148.22 40.57C147.8 40.35 147.38 40.14 146.96 39.93C146.83 39.87 146.78 39.77 146.78 39.64C146.78 39.24 146.78 38.85 146.78 38.45C146.78 38.33 146.83 38.24 146.95 38.18C147.35 37.98 147.74 37.77 148.13 37.57C148.24 37.52 148.34 37.51 148.45 37.57C148.84 37.77 149.25 37.98 149.64 38.18C149.69 38.21 149.74 38.24 149.77 38.29C149.8 38.33 149.81 38.4 149.81 38.45C149.81 38.86 149.81 39.26 149.81 39.66C149.81 39.78 149.76 39.87 149.64 39.93C149.22 40.14 148.8 40.36 148.37 40.58L148.22 40.57Z"
        fill="currentColor"
      />
      <path
        d="M147.88 36.11C147.88 36.32 147.88 36.54 147.88 36.75C147.88 36.89 147.83 36.99 147.7 37.06C147.31 37.28 146.91 37.5 146.52 37.72C146.47 37.75 146.41 37.76 146.36 37.76C146.31 37.76 146.25 37.74 146.2 37.72C145.8 37.5 145.42 37.28 145.03 37.07C144.91 37 144.85 36.9 144.85 36.76C144.85 36.33 144.85 35.9 144.85 35.47C144.85 35.33 144.9 35.22 145.03 35.16C145.42 34.95 145.82 34.73 146.2 34.51C146.24 34.48 146.31 34.47 146.36 34.47C146.41 34.47 146.47 34.49 146.52 34.51C146.91 34.73 147.32 34.95 147.71 35.17C147.82 35.23 147.89 35.33 147.88 35.46C147.88 35.67 147.88 35.89 147.88 36.11Z"
        fill="currentColor"
      />
      <path
        d="M141.95 22.49C141.32 22.17 140.69 21.85 140.05 21.53C139.85 21.44 139.77 21.3 139.77 21.09C139.78 20.5 139.78 19.91 139.77 19.32C139.77 19.13 139.85 19 140.03 18.91C140.62 18.62 141.22 18.31 141.8 18.01C141.97 17.92 142.12 17.92 142.28 18.01C142.87 18.31 143.47 18.61 144.07 18.92C144.15 18.96 144.21 19.01 144.27 19.09C144.31 19.16 144.34 19.24 144.34 19.33C144.34 19.93 144.34 20.52 144.34 21.12C144.34 21.31 144.25 21.44 144.08 21.53C143.44 21.85 142.81 22.17 142.18 22.49H141.95Z"
        fill="currentColor"
      />
      <path
        d="M141.44 15.88C141.44 16.2 141.44 16.51 141.44 16.83C141.44 17.04 141.36 17.18 141.17 17.28C140.58 17.6 139.99 17.93 139.4 18.26C139.33 18.3 139.24 18.32 139.16 18.32C139.07 18.32 138.99 18.3 138.92 18.25C138.33 17.93 137.75 17.6 137.15 17.28C136.96 17.18 136.87 17.03 136.87 16.82C136.88 16.19 136.88 15.55 136.87 14.91C136.87 14.69 136.95 14.55 137.15 14.45C137.74 14.13 138.33 13.8 138.92 13.48C138.99 13.44 139.07 13.41 139.16 13.41C139.25 13.41 139.33 13.43 139.4 13.47C139.99 13.8 140.6 14.12 141.19 14.45C141.36 14.54 141.44 14.69 141.44 14.88C141.43 15.23 141.44 15.56 141.44 15.88Z"
        fill="currentColor"
      />
      <path
        d="M132.16 41.21L130.86 43.26C134.48 49.09 126.7 50.92 126.7 50.92L117.65 46.01C117.65 46.01 116.22 37.46 122.77 38.45L124.12 36.26"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M117.81 42.27C117.81 42.27 120.58 44.79 122.91 42.3C124.77 40.31 127.71 40.9 128.17 44.42C128.38 46.05 129.27 47.5 132.14 46.38"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M132.79 41.66L123.37 36.16C123.2 36.06 123.08 35.9 123.02 35.73C122.96 35.56 122.98 35.39 123.06 35.25L123.79 34.06C123.88 33.92 124.02 33.83 124.2 33.8C124.38 33.77 124.57 33.82 124.74 33.92L134.03 39.63C134.2 39.73 134.32 39.89 134.38 40.06C134.44 40.23 134.42 40.4 134.34 40.54L133.74 41.52C133.65 41.66 133.51 41.75 133.33 41.78C133.15 41.8 132.95 41.76 132.79 41.66Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M51.6301 48.41C51.6001 48.38 51.5501 48.37 51.5001 48.37L45.5801 48.88L49.9601 53.43L56.1601 52.25L51.6301 48.41Z"
        fill="currentColor"
      />
      <path
        d="M45.5801 48.88L49.9601 53.43L53.4401 42.53L45.5801 48.88Z"
        fill="currentColor"
      />
      <path
        d="M53.4301 42.53L49.9501 53.43L56.1501 52.25L53.4301 42.53Z"
        fill="currentColor"
      />
      <path
        d="M45.79 50.32C45.67 50.2 45.47 50.3 45.53 50.45L48.4 58.31L49.66 54.35L45.79 50.32Z"
        fill="currentColor"
      />
      <path
        d="M49.66 54.35L48.4 58.31L55.3 53.57C55.43 53.48 55.33 53.28 55.16 53.31L49.66 54.35Z"
        fill="currentColor"
      />
      <path
        d="M52.06 19.57C52.04 19.57 52.02 19.58 52 19.6L50.14 21.76L53.16 21.85L54.89 19.37L52.06 19.57Z"
        fill="currentColor"
      />
      <path
        d="M50.14 21.75L53.16 21.84L50.7 16.94L50.14 21.75Z"
        fill="currentColor"
      />
      <path
        d="M50.71 16.95L53.17 21.85L54.9 19.37L50.71 16.95Z"
        fill="currentColor"
      />
      <path
        d="M50.7 22.18C50.62 22.18 50.58 22.28 50.65 22.31L54.27 24.04L53.37 22.26L50.7 22.18Z"
        fill="currentColor"
      />
      <path
        d="M53.38 22.26L54.28 24.04L55.05 20.11C55.06 20.03 54.96 20 54.92 20.07L53.38 22.26Z"
        fill="currentColor"
      />
      <path
        d="M22.52 30.25C27.71 31.76 33.06 29.03 34.48 24.15C35.9 19.27 32.85 14.09 27.67 12.58C22.49 11.07 17.13 13.8 15.71 18.68C14.29 23.56 17.33 28.73 22.52 30.25Z"
        fill="#f2af4a"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        d="M25.77 20.13C25.75 20.11 25.72 20.1 25.69 20.11L22.16 20.5L24.84 23.15L28.52 22.36L25.77 20.13Z"
        fill="currentColor"
      />
      <path
        d="M22.17 20.5L24.85 23.15L26.76 16.59L22.17 20.5Z"
        fill="currentColor"
      />
      <path
        d="M26.76 16.6L24.85 23.16L28.53 22.37L26.76 16.6Z"
        fill="currentColor"
      />
      <path
        d="M22.32 21.36C22.25 21.29 22.13 21.35 22.16 21.44L23.99 26.08L24.68 23.7L22.32 21.36Z"
        fill="currentColor"
      />
      <path
        d="M24.69 23.71L24 26.09L28.04 23.16C28.12 23.1 28.05 22.99 27.95 23.01L24.69 23.71Z"
        fill="currentColor"
      />
      <path
        d="M25.8299 44.59C25.8399 44.58 25.8499 44.57 25.8599 44.55L26.3199 42.5L24.3199 43.49L24.0499 45.71L25.8299 44.59Z"
        fill="currentColor"
      />
      <path
        d="M26.3199 42.5L24.3199 43.49L27.6199 45.83L26.3199 42.5Z"
        fill="currentColor"
      />
      <path
        d="M27.6199 45.83L24.3199 43.49L24.0499 45.71L27.6199 45.83Z"
        fill="currentColor"
      />
      <path
        d="M25.81 42.42C25.86 42.39 25.85 42.31 25.79 42.32L22.83 42.45L24.03 43.3L25.81 42.42Z"
        fill="currentColor"
      />
      <path
        d="M24.04 43.29L22.84 42.44L23.7 45.27C23.72 45.32 23.8 45.31 23.8 45.25L24.04 43.29Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_446_2857">
        <rect width="173" height="73" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HiWIllustration;
