import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { infuraProvider } from 'wagmi/providers/infura';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import Background from './components/Background';
import Header from './components/Header';
import Layout from './components/Layout';
import config from './constants/config';
import theme from './constants/theme';
import translations from './constants/translations';
import NetworkErrorsContainer from './containers/NetworkErrorsContainer';
import AccountGuard from './guards/AccountGuard';
import ConnectedPage from './pages/ConnectedPage';
import ConnectPage from './pages/ConnectPage';
import RefillingProvider from './providers/RefillingProvider';
import TranslationsProvider from './providers/TranslationsProvider';
import WalletAssetsProvider from './providers/WalletAssetsProvider';

const rinkebyProvider = jsonRpcProvider({
  rpc: () => ({
    http: config.quicknode.rpcUrl,
  }),
});

const { chains, provider } = configureChains(config.chains, [
  config.chains[0].name === 'Rinkeby'
    ? rinkebyProvider
    : infuraProvider({ apiKey: config.infura.infuraId }),
  publicProvider(),
]);

export const injectedConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: true,
  },
});

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    infuraId: config.infura.infuraId,
    qrcode: true,
  },
});

export const coinbaseWalletConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'Honey Jars Refill | FBM',
  },
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    injectedConnector,
    walletConnectConnector,
    coinbaseWalletConnector,
  ],
  provider,
});

const queryClient = new QueryClient();

function App() {
  return (
    <ChakraProvider theme={extendTheme(theme)}>
      <WagmiConfig client={wagmiClient}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />

          <TranslationsProvider translations={translations}>
            <Background />

            <AccountGuard
              yeyElement={
                <RefillingProvider>
                  <WalletAssetsProvider>
                    <Layout>
                      <Header />
                      <ConnectedPage />
                      <NetworkErrorsContainer />
                    </Layout>
                  </WalletAssetsProvider>
                </RefillingProvider>
              }
              neyElement={
                <Layout>
                  <ConnectPage />
                </Layout>
              }
            />
          </TranslationsProvider>
        </QueryClientProvider>
      </WagmiConfig>
    </ChakraProvider>
  );
}

export default App;
