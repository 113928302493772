import { Box, Button, Center, Heading, Icon, VStack } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { useCallback, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import EmptyState from '../components/EmptyState';
import JarTile from '../components/JarTile';
import Panel from '../components/Panel';
import RefillPopup from '../components/RefillPopup';
import useRefilling from '../hooks/useRefilling';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';

const JarsContainer = () => {
  const translate = useTranslate();

  const [selectedTokenId, setSelectedTokenId] = useState<number>();

  const { maxAllowedHoneyAmountInHoneyJar } = useRefilling();
  const { honeyJars, honeyInJars, readHoneyInJars } = useWalletAssets();

  const renderJarTile = useCallback(
    (tokenId: number) => {
      const isNotRefillable = maxAllowedHoneyAmountInHoneyJar?.lte(
        honeyInJars?.[tokenId] || BigNumber.from('0')
      );

      return (
        <JarTile
          key={tokenId}
          tokenId={tokenId}
          honey={honeyInJars?.[tokenId]}
          honeyLabel={translate('jars:honeyInHive')}
        >
          <Box px="1.5">
            <Button
              size="md"
              colorScheme={isNotRefillable ? 'dark' : 'primary'}
              isDisabled={isNotRefillable}
              onClick={() => {
                setSelectedTokenId(tokenId);
              }}
            >
              {translate('jars:refill')}
            </Button>
          </Box>
        </JarTile>
      );
    },
    [maxAllowedHoneyAmountInHoneyJar, honeyInJars, translate]
  );

  const handleRefillSuccess = useCallback(() => {
    readHoneyInJars();
    setSelectedTokenId(undefined);
  }, [readHoneyInJars]);

  return (
    <>
      <Panel>
        <Heading fontSize="2xl" p="1.5" pb="3">
          {translate('jars:title')}
        </Heading>

        {honeyJars && honeyJars.length ? (
          <VStack spacing="3">{honeyJars.map(renderJarTile)}</VStack>
        ) : (
          <Center h="60">
            <VStack spacing="3">
              <EmptyState
                opacity="0.8"
                label={translate('jars:empty')}
                maxW="10em"
              />

              <Button
                variant="outline"
                size="sm"
                rightIcon={<Icon as={FaExternalLinkAlt} />}
              >
                {translate('jars:buyOnOpenSea')}
              </Button>
            </VStack>
          </Center>
        )}
      </Panel>

      {selectedTokenId && (
        <RefillPopup
          isOpen
          tokenId={selectedTokenId}
          onSuccess={handleRefillSuccess}
          onClose={() => setSelectedTokenId(undefined)}
        />
      )}
    </>
  );
};

export default JarsContainer;
