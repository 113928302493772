import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { formatEther } from 'ethers/lib/utils';

export const formatEtherBalance = (value: BigNumber, decimals = 4) => {
  const result = Number(formatEther(value));
  return parseFloat(result.toFixed(decimals));
};

export const generateRandomInteger = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const inRange = (val: number, min: number, max: number) =>
  (val - min) * (val - max) <= 0;

export const formatEtherBalanceCompact = (value: BigNumberish) => {
  const formatter = Intl.NumberFormat('en', {
    notation: 'compact',
  });

  return formatter.format(parseInt(formatEther(value), 10));
};
