import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import config from '../constants/config';

const usePricePerHoney = (): [
  price: BigNumber | undefined,
  read: () => void
] => {
  const { data: price, refetch } = useContractRead({
    addressOrName: config.contracts.fancyHoneyJarsRefilling.address,
    contractInterface: config.contracts.fancyHoneyJarsRefilling.interface,
    functionName: 'pricePerHoneyETH',
  });

  return [price as BigNumber | undefined, refetch];
};

export default usePricePerHoney;
