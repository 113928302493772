import React from 'react';

const JarIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#honeyJarIconClipPath)">
      <path
        d="M8.06363 6.07513H22.0328C23.0455 6.07513 23.8493 5.15957 23.8493 4.04654C23.8493 2.93351 23.0295 2 22.0167 2H8.06363C7.05091 2 6.23108 2.91556 6.23108 4.02859C6.23108 5.14162 7.05091 6.07513 8.06363 6.07513Z"
        fill="currentColor"
      />
      <path
        d="M26.0034 21.514C25.3925 21.8012 24.7817 21.9448 24.1708 21.9628C22.5633 21.9807 21.0523 21.1549 19.6216 19.4854C17.9016 17.4568 15.8601 16.6131 14.0275 17.1696C12.5807 17.6004 11.5037 18.893 11.134 20.5984C10.4267 23.9196 8.16012 25.4275 5.89354 25.9841C5.29876 26.1277 5.10586 26.9355 5.53989 27.4023C5.55596 27.4202 5.55596 27.4202 5.57204 27.4382C6.52047 28.4794 7.82254 29 9.15677 29H21.2452C22.5312 29 23.7529 28.4076 24.7013 27.4202C26.0195 26.0559 26.6785 23.9375 26.984 22.4654C27.1126 21.8371 26.5499 21.2627 26.0034 21.514Z"
        fill="currentColor"
      />
      <path
        d="M9.06028 20.0419C9.60683 17.4568 11.2626 15.5179 13.4648 14.8537C16.0851 14.0638 18.8821 15.1589 21.1808 17.8338C22.1775 19.0007 23.1581 19.5572 24.1386 19.5572C24.8459 19.5392 25.5693 19.2161 26.3409 18.6057C26.6142 18.3903 26.7106 17.9594 26.566 17.6183C25.7301 15.5539 24.1547 13.7766 22.0971 12.5379V10.0605C22.0971 9.18085 21.4702 8.48071 20.6825 8.48071H9.59076C8.80308 8.48071 8.16008 9.18085 8.16008 10.0605V12.5379C5.04153 14.4229 3 17.5106 3 21.0293C3 21.8012 3.1286 22.5552 3.3215 23.2733C3.41795 23.6144 3.72338 23.8657 4.04488 23.8477C5.84528 23.758 8.41728 23.0758 9.06028 20.0419Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="honeyJarIconClipPath">
        <rect width="24" height="27" fill="white" transform="translate(3 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default JarIcon;
