import config from '../constants/config';
import CopyInput from '../components/CopyInput';
import Currency from '../types/currency';
import EtherAmount from '../components/EtherAmount';
import EthIcon from './EthIcon';
import FancyBearIcon from './FancyBearIcon';
import HoneyIcon from './HoneyIcon';
import JarIcon from './JarIcon';
import SimplePopup from '../components/SimplePopup';
import TinyHeading from '../components/TinyHeading';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';
import WalletAsset from '../components/WalletAsset';
import {
  Button,
  HStack,
  ModalProps,
  VStack
  } from '@chakra-ui/react';
import { getCurrencySymbol } from '../utils/currencyUtils';
import { useAccount, useDisconnect } from 'wagmi';
import { useNavigate } from 'react-router-dom';

type WalletPopupProps = Omit<ModalProps, 'children'>;

const WalletPopup = (props: WalletPopupProps) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { address } = useAccount();
  const { balance, honeyBalance, honeyJars, fancyBears } = useWalletAssets();
  const { disconnect } = useDisconnect();

  const handleDisconnect = () => {
    disconnect();
    navigate('/');
  };

  return (
    <SimplePopup size="sm" title={translate('walletPopup:title')} {...props}>
      <CopyInput value={address || ''} mt="6" mb="6" />

      <VStack spacing="2px" mb="6">
        <WalletAsset
          py="2"
          icon={EthIcon}
          value={<EtherAmount amount={balance} />}
        >
          <HStack pr="3">
            <TinyHeading>{getCurrencySymbol(Currency.Eth)}</TinyHeading>

            <Button
              as="a"
              href={config.urls.buyEthUrl}
              target="_blank"
              size="xs"
              variant="outline"
            >
              {translate('common:buy')}
            </Button>
          </HStack>
        </WalletAsset>

        <WalletAsset
          py="2"
          icon={HoneyIcon}
          value={<EtherAmount amount={honeyBalance} />}
        >
          <HStack pr="3">
            <TinyHeading>{getCurrencySymbol(Currency.Honey)}</TinyHeading>

            <Button
              as="a"
              href={config.urls.buyHoneyUrl}
              target="_blank"
              size="xs"
              variant="outline"
            >
              {translate('common:buy')}
            </Button>
          </HStack>
        </WalletAsset>

        <WalletAsset
          py="2"
          icon={FancyBearIcon}
          value={fancyBears?.length}
          iconSize="6"
        >
          <HStack pr="3">
            <TinyHeading>{translate('common:fancyBears')}</TinyHeading>

            <Button
              as="a"
              href={config.urls.fancyBearsCollectionUrl}
              target="_blank"
              size="xs"
              variant="outline"
            >
              {translate('common:buy')}
            </Button>
          </HStack>
        </WalletAsset>

        <WalletAsset
          py="2"
          icon={JarIcon}
          value={honeyJars?.length}
          iconSize="6"
        >
          <HStack pr="3">
            <TinyHeading>{translate('common:honeyJars')}</TinyHeading>

            <Button
              as="a"
              href={config.urls.honeyJarsCollectionUrl}
              target="_blank"
              size="xs"
              variant="outline"
            >
              {translate('common:buy')}
            </Button>
          </HStack>
        </WalletAsset>
      </VStack>

      <Button
        onClick={handleDisconnect}
        variant="solid"
        colorScheme="dark"
        w="full"
      >
        {translate('common:disconnect')}
      </Button>
    </SimplePopup>
  );
};

export default WalletPopup;
