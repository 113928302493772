import {
  AspectRatio,
  Badge,
  Box,
  BoxProps,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { BigNumber } from 'ethers';
import config from '../constants/config';
import { NftMetadata } from '../types/nft';
import EtherAmount from './EtherAmount';
import HoneyIcon from './HoneyIcon';
import TinyHeading from './TinyHeading';

type JarTileProps = BoxProps & {
  tokenId: number;
  honey?: BigNumber;
  honeyLabel?: string;
};

const JarTile = ({
  tokenId,
  honey,
  honeyLabel,
  children,
  ...rest
}: JarTileProps) => {
  const { data: metadata, isFetched } = useQuery<NftMetadata>({
    queryKey: ['jar', tokenId],
    queryFn: () =>
      fetch(`${config.urls.honeyJarsMetadataBaseUrl}/${tokenId}`).then(res =>
        res.json()
      ),
  });

  return (
    <Box bg="dark.800" borderRadius="lg" p="1.5" w="full" {...rest}>
      <HStack spacing="3">
        <AspectRatio
          ratio={1}
          w="20"
          bg="dark.600"
          borderRadius="md"
          flex="none"
        >
          {isFetched ? (
            <Image src={metadata?.image} bg="dark.600" borderRadius="md" />
          ) : (
            <></>
          )}
        </AspectRatio>

        <VStack align="start" spacing="2" w="full">
          <Wrap spacing="3" align="center">
            <Text fontSize="lg" fontWeight="semibold">
              #{tokenId}
            </Text>

            {metadata?.attributes
              .filter(attr => attr.trait_type === 'Color')
              .map(attr => (
                <Badge key={attr.value} bg="dark.900" color="dark.100">
                  {attr.value}
                </Badge>
              ))}
          </Wrap>

          {honey && (
            <Box>
              {honeyLabel && <TinyHeading mb="1">{honeyLabel}</TinyHeading>}

              <Wrap color="primary.500">
                <Icon as={HoneyIcon} w="4" h="4" />
                <EtherAmount amount={honey} />
              </Wrap>
            </Box>
          )}
        </VStack>

        {children}
      </HStack>
    </Box>
  );
};

export default JarTile;
