const translations = {
  'common:copy': 'Copy',
  'common:copied': 'Copied to clipboard!',
  'common:disconnect': 'Disconnect',
  'common:free': 'Free',
  'common:claim': 'Claim',
  'common:buy': 'Buy',
  'common:refresh': 'Refresh',
  'common:cancel': 'Cancel',
  'common:stake': 'Stake',
  'common:unstake': 'Unstake',
  'common:noResults': 'No results…',
  'common:fancyBear': 'Fancy Bear',
  'common:fancyBears': 'Fancy Bears',
  'common:level': 'Level',
  'common:honey': '$HONEY',
  'common:honeyJar': 'Honey Jar',
  'common:honeyJars': 'Honey Jars',

  'error:default': 'Oops! Something went wrong.',

  'success:default': 'Success! Thank you.',

  'connect:appTitle': 'Honey Jars Refill',
  'connect:appDescription': '$HONEY Production Facility',
  'connect:title': 'Connect Your Wallet',
  'connect:button:label': 'Connect with {connector}',

  'footer:learnMore': 'About',
  'footer:powered': 'Powered by',
  'footer:contract': 'Contract',

  'walletPopup:title': 'Your Wallet',

  'howItWorks:title': 'Curious How it Works?',
  'howItWorks:learnMore': 'Learn More',
  'howItWorks:popupTitle': 'How it Works',

  'jars:title': 'Your Honey Jars',
  'jars:honeyInHive': '$HONEY in Hive',
  'jars:refill': 'Refill',
  'jars:empty': "You don't own any Honey Jars",
  'jars:buyOnOpenSea': 'Buy on OpenSea',

  'refill:title': 'Refill Honey Jar #{tokenId}',
  'refill:description':
    'You can refill this Honey Jar with a maximum of {honeyAmount} $HONEY (~{ethAmount} ETH)',
  'refill:honeyInHive': 'Current Amount',
  'refill:submit': 'Refill',

  'unsupportedNetwork:title': 'Wrong Network',
  'unsupportedNetwork:description':
    'Your wallet is connected to an unsupported network. Please change it to: {chainName}',
  'unsupportedNetwork:switch': 'Switch Network',
};

export default translations;
