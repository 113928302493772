import ErrorDetails from './ErrorDetails';
import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

export type ErrorPopupProps = {
  isOpen: boolean;
  icon?: React.ReactNode;
  title: string;
  description?: string;
  children?: JSX.Element | null;
  onClose?: () => void;
};

const ErrorPopup = ({
  isOpen,
  icon,
  title,
  description,
  onClose,
  children,
}: ErrorPopupProps) => (
  <Modal
    isOpen={isOpen}
    {...(onClose
      ? {
          onClose,
        }
      : {
          closeOnOverlayClick: false,
          closeOnEsc: false,
          onClose: () => {},
        })}
    autoFocus={false}
    size="sm"
  >
    <ModalOverlay />

    <ModalContent>
      {onClose && <ModalCloseButton />}

      <ModalBody
        display="flex"
        flexDir="column"
        alignItems="center"
        textAlign="center"
        px="8"
        py="8"
      >
        <ErrorDetails icon={icon} title={title} description={description}>
          {children}
        </ErrorDetails>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default ErrorPopup;
