import { Center } from '@chakra-ui/react';
import { FunctionComponent, useMemo } from 'react';
import BoldSpinner from '../components/BoldSpinner';
import RefillingContext, {
  RefillingContextValue,
} from '../contexts/RefillingContext';
import useMaxAllowedHoneyAmountInHoneyJar from '../hooks/useMaxAllowedHoneyAmountInHoneyJar';
import usePricePerHoney from '../hooks/usePricePerHoney';
import useRefillingStatus from '../hooks/useRefillingStatus';

const RefillingProvider: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const [refillingStatus, readRefillingStatus] = useRefillingStatus();

  const [pricePerHoney, readPricePerHoney] = usePricePerHoney();
  const [maxAllowedHoneyAmountInHoneyJar, readMaxAllowedHoneyAmountInHoneyJar] =
    useMaxAllowedHoneyAmountInHoneyJar();

  const isLoading =
    refillingStatus === undefined ||
    pricePerHoney === undefined ||
    maxAllowedHoneyAmountInHoneyJar === undefined;

  const contextValue = useMemo<RefillingContextValue>(
    () => ({
      refillingStatus,
      readRefillingStatus,
      pricePerHoney,
      readPricePerHoney,
      maxAllowedHoneyAmountInHoneyJar,
      readMaxAllowedHoneyAmountInHoneyJar,
      isLoading,
    }),
    [
      refillingStatus,
      pricePerHoney,
      maxAllowedHoneyAmountInHoneyJar,
      readMaxAllowedHoneyAmountInHoneyJar,
      readPricePerHoney,
      readRefillingStatus,
      isLoading,
    ]
  );

  return (
    <RefillingContext.Provider value={contextValue}>
      {isLoading ? (
        <Center h="100vh">
          <BoldSpinner size="xl" />
        </Center>
      ) : (
        children
      )}
    </RefillingContext.Provider>
  );
};

export default RefillingProvider;
