import { Center } from '@chakra-ui/react';
import { FunctionComponent, useMemo } from 'react';
import { useAccount, useBalance } from 'wagmi';
import BoldSpinner from '../components/BoldSpinner';
import config from '../constants/config';
import WalletAssetsContext, {
  WalletAssetsContextValue,
} from '../contexts/WalletAssetsContext';
import useFancyBearsInWallet from '../hooks/useFancyBearsInWallet';
import useHoneyBalance from '../hooks/useHoneyBalance';
import useHoneyInHive from '../hooks/useHoneyInHive';
import useHoneyJarsInWallet from '../hooks/useHoneyJarsInWallet';

const WalletAssetsProvider: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const { address } = useAccount();

  const { data: balance, refetch: readBalance } = useBalance({
    addressOrName: address,
  });

  const [honeyBalance, readHoneyBalance] = useHoneyBalance(address);

  const [fancyBears, readFancyBears] = useFancyBearsInWallet(address);
  const [honeyJars, readHoneyJars] = useHoneyJarsInWallet(address);

  const [honeyInJars, readHoneyInJars] = useHoneyInHive(
    config.contracts.honeyJarsContract.address,
    honeyJars
  );

  const isLoading =
    fancyBears === undefined ||
    honeyBalance === undefined ||
    honeyJars === undefined ||
    balance === undefined;

  const contextValue = useMemo<WalletAssetsContextValue>(
    () => ({
      balance: balance?.value,
      readBalance,
      fancyBears,
      readFancyBears,
      honeyBalance,
      readHoneyBalance,
      honeyJars,
      readHoneyJars,
      honeyInJars,
      readHoneyInJars,
      isLoading,
    }),
    [
      balance,
      readBalance,
      fancyBears,
      readFancyBears,
      honeyBalance,
      readHoneyBalance,
      honeyJars,
      readHoneyJars,
      honeyInJars,
      readHoneyInJars,
      isLoading,
    ]
  );

  return (
    <WalletAssetsContext.Provider value={contextValue}>
      {isLoading ? (
        <Center h="100vh">
          <BoldSpinner size="xl" />
        </Center>
      ) : (
        children
      )}
    </WalletAssetsContext.Provider>
  );
};

export default WalletAssetsProvider;
