import { BigNumber } from 'ethers';
import { useMemo } from 'react';

const PREDEFINDED_VALUES = [
  BigNumber.from('10000000000000000000000'),
  BigNumber.from('15000000000000000000000'),
  BigNumber.from('30000000000000000000000'),
  BigNumber.from('50000000000000000000000'),
  BigNumber.from('71000000000000000000000'),
];

const useRefillPredefinedValues = (maxAmount?: BigNumber) => {
  return useMemo(() => {
    if (!maxAmount) {
      return [];
    }

    const result = PREDEFINDED_VALUES.filter(value => {
      if (!maxAmount) {
        return [];
      }

      return value.lt(maxAmount);
    });

    result.push(maxAmount);

    return result;
  }, [maxAmount]);
};

export default useRefillPredefinedValues;
