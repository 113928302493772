import {
  Box,
  BoxProps,
  Button,
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { BigNumber, BigNumberish } from 'ethers';
import React, { Fragment, useCallback } from 'react';
import { formatEtherBalanceCompact } from '../utils/numberUtils';
import { BigNumberInput, BigNumberInputProps } from './BigNumberInput';

type RefillAmountInputProps = BigNumberInputProps & {
  icon: React.ReactNode;
  hint?: string;
  badge?: string;
  badgeW?: BoxProps['w'];
  predefinedValues?: BigNumberish[];
};

const RefillAmountInput = ({
  icon,
  hint,
  max,
  predefinedValues,
  badge,
  badgeW = 'auto',
  isDisabled,
  onChange,
  ...rest
}: RefillAmountInputProps) => {
  const renderPredefinedValue = useCallback(
    (amount: BigNumberish, isMax: boolean) => (
      <Button
        size="xs"
        colorScheme="dark"
        variant="outline"
        borderRadius="full"
        isDisabled={isDisabled}
        onClick={() => {
          onChange(amount);
        }}
      >
        {isMax ? 'MAX' : formatEtherBalanceCompact(amount)}
      </Button>
    ),
    [isDisabled, onChange]
  );

  return (
    <Box w="full" bg="dark.800" borderRadius="lg" p="5">
      <InputGroup size="lg" w="full">
        <InputLeftElement pointerEvents="none" children={icon} />
        <BigNumberInput
          size="lg"
          pl="10"
          pr={badgeW === 'auto' ? undefined : badgeW}
          max={max}
          isDisabled={isDisabled}
          onChange={onChange}
          {...rest}
        />

        {badge && (
          <InputRightElement
            pointerEvents="none"
            fontSize="sm"
            w={badgeW}
            px="4"
            textAlign="right"
            children={<Text>{badge}</Text>}
          />
        )}
      </InputGroup>

      {hint && (
        <Text mt="0.5" fontSize="xs" opacity="0.6" px="1.5">
          {hint}
        </Text>
      )}

      {predefinedValues && (
        <HStack mt="2" justifyContent="center" spacing="2">
          {predefinedValues
            .filter(amount =>
              max ? BigNumber.from(amount).lte(BigNumber.from(max)) : true
            )
            .map((amount, index) => (
              <Fragment key={index}>
                {renderPredefinedValue(
                  amount,
                  index === predefinedValues.length - 1
                )}
              </Fragment>
            ))}
        </HStack>
      )}
    </Box>
  );
};
export default RefillAmountInput;
