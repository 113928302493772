import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';
import HiWIllustration from '../components/HiWIllustration';
import SimplePopup from '../components/SimplePopup';
import useTranslate from '../hooks/useTranslate';

const HowItWorksContainer = () => {
  const translate = useTranslate();

  const [isHiWPopupOpen, setIsHiWPopupOpen] = useBoolean();

  return (
    <>
      <Box p="4" bg="primary.500" w="full" borderRadius="lg" color="dark.900">
        <HStack justify="space-between">
          <VStack align="start">
            <Heading size="md">{translate('howItWorks:title')}</Heading>

            <Button
              size="xs"
              colorScheme="dark"
              bg="dark.900"
              leftIcon={
                <Icon as={FaInfoCircle} color="primary.500" w="4" h="4" />
              }
              mt="2"
              onClick={setIsHiWPopupOpen.on}
            >
              {translate('howItWorks:learnMore')}
            </Button>
          </VStack>

          <HiWIllustration width="40%" />
        </HStack>
      </Box>

      {isHiWPopupOpen && (
        <SimplePopup
          isOpen
          title={translate('howItWorks:popupTitle')}
          onClose={setIsHiWPopupOpen.off}
        >
          <Box mt="6" textAlign="left" px="6" pb="6">
            <Text opacity="0.8" fontSize="sm">
              Our facility was established by Fancy Bears in order to provide
              cheap and high quality $HONEY for everyone. Bears need to do
              something in their free time - part of the team was delegated to
              work in an in-house apiary. $HONEY you are buying here will be
              locked inside the Honey Jars, you can use it only inside the Trait
              Swap ecosystem. It's also cheaper than on the market (our little
              secret).
            </Text>
            <br />
            <Text fontWeight="bold" fontSize="sm">
              How can you use it?
            </Text>
            <br />
            <Box as="ul" fontSize="sm" listStyleType="none" opacity="0.8">
              <li>
                A. For minting traits from the Trait Swap ($HONEY is the TS
                primary token)
              </li>
              <li>B. For leveling up your Fancy Bears </li>
              <li>C. For participation in the staking system </li>
              <li>D. To get discounts and early access to drops</li>
              <li>D. For governance</li>
            </Box>
            <br />
            <Text fontSize="sm" opacity="0.8">
              More use cases will be announced in future!
            </Text>
          </Box>
        </SimplePopup>
      )}
    </>
  );
};

export default HowItWorksContainer;
