import { chain } from 'wagmi';
import fancyBearsABI from '../abis/fancyBears.abi.json';
import fancyHoneyJarsReffilingABI from '../abis/fancyHoneyJarsReffiling.abi.json';
import hiveABI from '../abis/hive.abi.json';
import honeyJarsABI from '../abis/honeyJars.abi.json';
import honeyTokenABI from '../abis/honeyToken.abi.json';

const isTestnet = parseInt(process.env.REACT_APP_USE_TESTNET!, 10) === 1;

const config = {
  environment: process.env.NODE_ENV as 'production' | 'development',
  isTestnet,

  sentry: {
    sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
  },

  ga: {
    gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID!,
  },

  infura: {
    infuraId: process.env.REACT_APP_INFURA_ID!,
  },

  quicknode: {
    rpcUrl: process.env.REACT_APP_QUICKNODE_RPC_URL!,
  },

  chains: isTestnet ? [chain.rinkeby] : [chain.mainnet],

  contracts: {
    honeyTokenContract: {
      address: process.env.REACT_APP_HONEY_TOKEN_CONTRACT_ADDRESS!,
      interface: honeyTokenABI,
    },
    fancyBearsContract: {
      address: process.env.REACT_APP_FANCY_BEARS_CONTRACT_ADDRESS!,
      interface: fancyBearsABI,
    },
    honeyJarsContract: {
      address: process.env.REACT_APP_HONEY_JARS_CONTRACT_ADDRESS!,
      interface: honeyJarsABI,
    },
    hiveContract: {
      address: process.env.REACT_APP_HIVE_CONTRACT_ADDRESS!,
      interface: hiveABI,
    },
    fancyHoneyJarsRefilling: {
      address:
        process.env.REACT_APP_FANCY_HONEY_JARS_REFFILNG_CONTRACT_ADDRESS!,
      interface: fancyHoneyJarsReffilingABI,
    },
  },

  urls: {
    honeyJarsMetadataBaseUrl:
      process.env.REACT_APP_HONEY_JARS_METADATA_BASE_URL,
    fancyBearsCollectionUrl: process.env.REACT_APP_FANCY_BEARS_COLLECTION_URL!,
    honeyJarsCollectionUrl: process.env.REACT_APP_HONEY_JARS_COLLECTION_URL!,
    publicUrl: process.env.PUBLIC_URL!,
    apiUrl: process.env.REACT_APP_API_URL!,
    buyEthUrl: process.env.REACT_APP_BUY_ETH_URL!,
    buyHoneyUrl: process.env.REACT_APP_BUY_HONEY_URL!,
    learnMoreUrl: process.env.REACT_APP_LEARN_MORE_URL!,
    discordUrl: process.env.REACT_APP_DISCORD_URL!,
    twitterUrl: process.env.REACT_APP_TWITTER_URL!,
  },
};

export default config;
