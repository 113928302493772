import { BigNumber } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { useContractRead } from 'wagmi';
import config from '../constants/config';

const useHoneyInHive = (
  collectionAddress?: string,
  tokenIds?: number[]
): [honeyInTokens: Record<number, BigNumber> | undefined, read: () => void] => {
  const [honeyInTokens, setHoneyInTokens] =
    useState<Record<number, BigNumber>>();

  const args = useMemo(() => {
    if (!tokenIds) {
      return null;
    }

    return [collectionAddress, tokenIds];
  }, [tokenIds, collectionAddress]);

  const { data: honeyInTokensData, refetch } = useContractRead({
    addressOrName: config.contracts.hiveContract.address,
    contractInterface: config.contracts.hiveContract.interface,
    functionName: 'getBalanceByTokenIdsOfCollection',
    enabled: !!args,
    args,
  });

  useEffect(() => {
    if (tokenIds && honeyInTokensData !== undefined) {
      const data = honeyInTokensData as [BigNumber[], BigNumber[]];

      setHoneyInTokens(
        tokenIds.reduce(
          (prev, tokenId, i) => ({
            ...prev,
            [tokenId]: data[i],
          }),
          {}
        ) as Record<number, BigNumber>
      );
    }
  }, [tokenIds, honeyInTokensData]);

  return [honeyInTokens, refetch];
};

export default useHoneyInHive;
