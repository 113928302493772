import { BigNumber, BigNumberish } from 'ethers';

export const calculateHoneyToETH = (
  honeyAmount: BigNumberish,
  pricePerHoney: BigNumberish
) => {
  const asBn = BigNumber.from(honeyAmount);
  return asBn
    .div('1000000000000000000')
    .mul(pricePerHoney)
    .add(
      asBn
        .mod('1000000000000000000')
        .mul(pricePerHoney)
        .div('1000000000000000000')
    );
};

export const calculateETHToHoney = (
  ethAmount: BigNumberish,
  pricePerHoney: BigNumberish
) => {
  const asBn = BigNumber.from(ethAmount);

  return asBn
    .div(pricePerHoney)
    .mul('1000000000000000000')
    .add(asBn.mod(pricePerHoney).mul('1000000000000000000').div(pricePerHoney));
};
