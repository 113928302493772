import { useContractRead } from 'wagmi';
import config from '../constants/config';

enum RefillingStatus {
  On,
  Off,
}

const useRefillingStatus = (): [
  status: RefillingStatus | undefined,
  read: () => void
] => {
  const { data: status, refetch } = useContractRead({
    addressOrName: config.contracts.fancyHoneyJarsRefilling.address,
    contractInterface: config.contracts.fancyHoneyJarsRefilling.interface,
    functionName: 'refillingStatus',
  });

  return [status as RefillingStatus | undefined, refetch];
};

export default useRefillingStatus;
