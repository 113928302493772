import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import config from '../constants/config';

const useMaxAllowedHoneyAmountInHoneyJar = (): [
  amount: BigNumber | undefined,
  read: () => void
] => {
  const { data: amount, refetch } = useContractRead({
    addressOrName: config.contracts.fancyHoneyJarsRefilling.address,
    contractInterface: config.contracts.fancyHoneyJarsRefilling.interface,
    functionName: 'maxAllowedHoneyAmountInHoneyJar',
  });

  return [amount as BigNumber | undefined, refetch];
};

export default useMaxAllowedHoneyAmountInHoneyJar;
