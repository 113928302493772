import { BigNumber } from 'ethers';
import React from 'react';

export type RefillingContextValue = {
  isLoading: boolean;
  refillingStatus?: number;
  readRefillingStatus: () => void;
  pricePerHoney?: BigNumber;
  readPricePerHoney: () => void;
  maxAllowedHoneyAmountInHoneyJar?: BigNumber;
  readMaxAllowedHoneyAmountInHoneyJar: () => void;
};

// @ts-ignore
const RefillingContext = React.createContext<RefillingContextValue>();

RefillingContext.displayName = 'RefillingContext';

export default RefillingContext;
