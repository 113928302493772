import { BigNumber, BigNumberish } from 'ethers';
import { useEffect, useMemo } from 'react';
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import config from '../constants/config';
import useSnackbarRpcError from './useSnackbarRpcError';
import useSnackbarRpcSuccess from './useSnackbarRpcSuccess';

const useRefillHoneyJar = (
  tokenId?: number,
  amountOfHoney?: BigNumberish,
  ethAmount?: BigNumberish,
  onSuccess?: () => void
): [
  refill: ReturnType<typeof useContractWrite>['write'],
  isLoading: boolean
] => {
  const args = useMemo(() => {
    if (tokenId && amountOfHoney && BigNumber.from(amountOfHoney).gt('0')) {
      return [tokenId, amountOfHoney];
    }
  }, [tokenId, amountOfHoney]);

  const { config: writeConfig } = usePrepareContractWrite({
    addressOrName: config.contracts.fancyHoneyJarsRefilling.address,
    contractInterface: config.contracts.fancyHoneyJarsRefilling.interface,
    functionName: 'purchaseHoneyToJarWithETH',
    enabled: !!args,
    args,
    overrides: {
      value: ethAmount,
    },
  });

  const {
    write,
    data: writeData,
    error: writeError,
    isLoading,
  } = useContractWrite(writeConfig);

  const {
    data: waitData,
    error: waitError,
    isLoading: isWaitLoading,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    confirmations: 2,
  });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useEffect(() => {
    if (!isLoading && waitData && onSuccess) {
      onSuccess();
    }
  }, [isLoading, waitData, onSuccess]);

  return [write, isLoading || isWaitLoading];
};

export default useRefillHoneyJar;
