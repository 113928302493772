import * as React from 'react';
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { Input, InputProps } from '@chakra-ui/input';

export type BigNumberInputProps = {
  value: BigNumberish;
  max?: BigNumberish;
  min?: BigNumberish;
  decimals?: number;
  onChange: (value: BigNumberish) => void;
} & Omit<InputProps, 'onChange' | 'max' | 'min' | 'value'>;

export const BigNumberInput: React.FC<BigNumberInputProps> = ({
  value,
  max,
  min = '0',
  decimals = 18,
  onChange,
  ...rest
}) => {
  const inputRef = React.useRef<any>(null);

  const [val, setVal] = React.useState('');

  React.useEffect(() => {
    if (!value) {
      setVal('');
    } else {
      let parsedValue;
      try {
        parsedValue = parseUnits(val || '0', decimals);
      } catch {}

      if (!parsedValue || !parsedValue.eq(value)) {
        setVal(formatUnits(value, decimals));
      }
    }
  }, [value, decimals, val]);

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    if (value === '') {
      onChange('0');
      setVal(value);
      return;
    }

    let newVal: BigNumber;
    try {
      newVal = parseUnits(value, decimals);
    } catch {
      return;
    }

    const isInvalid = (min && newVal.lt(min)) || (max && newVal.gt(max));
    if (isInvalid) {
      return;
    }

    setVal(value);
    onChange(newVal);
  };

  return <Input value={val} ref={inputRef} onChange={updateValue} {...rest} />;
};
